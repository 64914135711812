import React from "react";

function Stages() {
  return (
    <section className="stage_area section_padding" id="presale_stage">
      <div className="container">
        <div className="section_title">
          <h2>Presale Stages</h2>
          <p>
          Professional research of the crypto market has led us to draft our goals  and target in a orderly and realistic timeframe.
          </p>
          <br />
        </div>
        <div className="stage">
          <img src="assets/images/stage/stagess.png" alt="" />
          <div className="stage_item stage_item_1" data-aos="fade-right">
            <h5>Stage 1</h5>
            <p>Price : $0.035</p>
            <p>Duration: 20 Days (16th Dec, 2023 - 5th Jan, 2024)</p>
            <p>Amount to be Sold: 4,000,000 CYBT</p>
          </div>
          <div className="stage_item stage_item_2" data-aos="fade-left">
          <h5>Stage 2</h5>
            <p>Price : $0.040</p>
            <p>Duration: 20 Days (5th Jan, 2024 - 25th Jan, 2024)</p>
            <p>Amount to be Sold: 4,000,000 CYBT</p>
          </div>
          <div className="stage_item stage_item_3" data-aos="fade-right">
          <h5>Stage 3</h5>
            <p>Price : $0.045</p>
            <p>Duration: 20 Days (25th Jan, 2024 - 13th Feb, 2024)</p>
            <p>Amount to be Sold: 4,000,000 CYBT</p>
          </div>
          <div className="stage_item stage_item_4" data-aos="fade-left">
            <h5>Listing Price</h5>
            <p>Price : $0.05</p>
          </div>
         
        </div>
      </div>
    </section>
  );
}

export default Stages;
