import React from "react";

function Footer() {
  return (
    <footer className="footer_area">
      <div className="join_main_footer">
        <div className="join_area section_padding">
          <div className="container">
            <div className="main_join">
              <div className="join_left">
                <h3>STAY UPDATED ON $CYBT NEWS <br /> Join Our Community Today!</h3>
                <p>
                Join our Twitter/Telegram/TikTok community to connect with like-minded individuals and stay updated on the latest news and events. Have ideas or skills you'd like to share? Become an active contributor to the $CYBT ecosystem and also get rewarded
                </p>
              </div>
              <div className="join_right">
                <a href="https://t.me/cybttoken" target="_blank">Join Our Telegram</a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="footer_main">
          <div className="footer">
            <div className="footer_logo">
              <div className="logo">
                <a href="#">
                  <img src="assets/images/logo.png" alt="" />
                  <span className="footer_logo_text">Cybertruck</span>
                </a>
              </div>
              <p>
                Disclaimer: Please remember that investing in cryptocurrencies carries significant risks. This information is for educational purposes only and should not be considered financial advice. Always conduct your own research before investing in any cryptocurrency project.
              </p>
                  {/* Show on Desktop */}
                {/* <div className="solidproof mobile1-hide"> <a href="https://app.solidproof.io/projects/btcinu?audit_id=808" target="_blank">
              <img src="assets/images/solidprooflogo.png" alt="" className="solidproofimg"/></a>
              </div> */}

                  {/* Show only on Mobile */}
                  {/* <div className="solidproofmobile desktop1-hide"> <a href="https://app.solidproof.io/projects/btcinu?audit_id=808" target="_blank">
              <img src="assets/images/solidprooflogo.png" alt="" className="solidproofmobileimg"/></a>
              </div> */}

            </div>
            <div className="footer_item">
              <h4>Quick links</h4>
              <ul>
                <li>
                  <a href="#Roadmap">Roadmap</a>
                </li>
                <li>
                  <a href="#how_to_buy">How to buy</a>
                </li>
                <li>
                  <a href="#faq">FAQ</a>
                </li>
                <li>
                  <a href="#Tokenomics">Tokenomics</a>
                </li>
              </ul>
            </div>
            <div className="footer_item">
              <h4>Company</h4>
              <ul>
                <li>
                  <a
                    href="assets/pdf/Privacy-Policy.pdf"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="assets/pdf/terms-and-condition.pdf"
                    target="_blank"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="assets/pdf/cookies.pdf"
                    target="_blank"
                  >
                    Cookies
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer_item">
              <h4>Get in touch</h4>
              <ul className="social_footer">
                <li>
                  <a href="https://t.me/cybttoken" target="_blank">
                    <img src="assets/images/header/teligram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/cybttoken" target="_blank">
                    <img src="assets/images/header/x.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@cybttoken" target="_blank">
                    <img src="assets/images/header/tiktok.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/cybttoken" target="_blank">
                    <img src="assets/images/header/instagram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/@cybttoken" target="_blank">
                    <img src="assets/images/header/youtube.png" alt="" />
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="admin@cybertrucktoken.xyz">
                  admin@cybertrucktoken.xyz
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="footer_copy">
            <p>Copyright © 2023 CYBT Token. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
