import React from "react";

function Roadmap() {
  return (
    <section className="roadmap_area section_padding" id="Roadmap">
      <div className="container">
        <div className="section_title">
          <h2>ROADMAP</h2>
          <p>
          A timeline of key milestones and achievements, future plans and objectives
          </p>
        </div>
        <div className="roadmap_main">
          {/* <div className="dot_white_1">
            <img src="assets/images/roadmap/line1.png" alt="" />
          </div>
          <div className="dot_white_2">
            <img src="assets/images/roadmap/line2.png" alt="" />
          </div>
          <div className="dot_white_3">
            <img src="assets/images/roadmap/line1.png" alt="" />
          </div> */}
          {/* single roadmap  */}
          <div className="roadmap_single">
            <a href="#">PHASE 1</a>
            <h3>Q3 2023</h3>
            <hr />
            <ul>
              <li>
                <p>
                  <span />
                 Market Research
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Team Development
                </p>
              </li>
            
            </ul>
          </div>
          {/* single roadmap  */}
          <div className="roadmap_single">
            <a href="#">PHASE 2</a>
            <h3>Q4 2023</h3>
            <hr />
            <ul>
              <li>
                <p>
                  <span />
                  Website Development
                </p>
              </li>
              <li>
                <p>
                  <span />
                 Token Creation
                </p>
              </li>
              <li>
                <p>
                  <span />
                 1st Stage of Presale
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Social Media Marketing Campaign
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Social Media Contests
                </p>
              </li>
            </ul>
          </div>
          {/* single roadmap  */}
          <div className="roadmap_single">
            <a href="#">PHASE 3</a>
            <h3>Q1 2024</h3>
            <hr />
            <ul>
              <li>
                <p>
                  <span />
                  2nd Stage of Presale
                </p>
              </li>
              <li>
                <p>
                  <span />
                  3rd Stage of Presale
                </p>
              </li>
              <li>
                <p>
                  <span />
                 Marketing Campaign
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Token Launch 
                </p>
              </li>
              <li>
                <p>
                  <span />
                  DEX Listing (UNISWAP)
                </p>
              </li>
              <li>
                <p>
                  <span />
                 Listing on Coingecko, CMC, Defilama, Dexscreener e.t.c
                </p>
              </li>
            </ul>
          </div>
          {/* single roadmap  */}
          <div className="roadmap_single">
            <a href="#">PHASE 4</a>
            <h3>Q2 2024</h3>
            <hr />
            <ul>
              <li>
                <p>
                  <span />
                  Partnership
                </p>
              </li>
              <li>
                <p>
                  <span />
                 CEX Listing
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Continuous Promotion
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Community Development
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
