import React from "react";

function Features() {
  return (
    <>
      <section className="featear_area section_padding">
        <div className="container">
          <div className="feature_title">
            <h3>FEATURED IN</h3>
          </div>
          <div className="features">
          <div className="feature_item">
              <img src="assets/images/feature/1.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/2.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/3.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/4.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/5.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/6.png" alt="" />
            </div>
            


            


           


          </div>

        </div>
      </section>
      <section className="change_area section_padding">
        <div className="container">
          <div className="chagne_row">
            <div className="change_left">
              <img src="assets/images/change/cybertruck.png" alt="" />
            </div>
            <div className="change_right">
              <h3>Get Your Cybertruck Token Now! <br />
              $CYBT is Built for the Future</h3>
              <p>
              The Cybertruck is engineered with cutting-edge technology and materials to withstand even the most demanding tasks. Its exoskeleton design provides unparalleled strength and protection, while its adaptive air suspension ensures a smooth ride on any terrain. <br /> <br />With a limited supply and regular burn events, the value of $CYBT is designed to increase over time. Buy $CYBT tokens and join the community.

              </p>
              <a href="https://www.t.me/cybttoken" target="_blank" className="btn_3 tokenomics_scroll">
                Join Us Now
              </a>
            </div>
          </div>
        </div>
        {/* <div className="shape_chagne">
          <img src="assets/images/change/shape.png" alt="" />
        </div> */}
      </section>
    </>
  );
}

export default Features;
