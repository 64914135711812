import React from "react";

function Map() {
  return (
    <section className="map_area section_padding">
      <div className="container">
        <div className="maps">
          <div className="maps_left">
            <h3>whY yoU should ape in Early</h3>
            <ul className="map_ul">
              <li>
                <img src="assets/images/map/check.png" alt="Check Image" />
                <div className="map_text">
                  <h4>TRENDING ON X (Formerly Known as Twitter) </h4>
                  <p>
                  The Cybertruck is one of the most controversial automobiles ever created and it’s creation has sparked a lot of conversations on several social media including X (former twitter) which was also recently acquired by Elon Musk  (tech billionaire and owner of Tesla and space X)
                  </p>
                </div>
              </li>
              <li>
                <img src="assets/images/map/check.png" alt="Check Image" />
                <div className="map_text">
                  <h4>INSTANT PROFITS </h4>
                  <p>
                  The Presale prices are much lower than the launch price, early Presale investors have assured profits at launch date. Which means, the listing price will be higher than the Presale prices and those bought at presale will be in significant profits.
                  </p>
                </div>
              </li>
              <li>
                <img src="assets/images/map/check.png" alt="Check Image" />
                <div className="map_text">
                  <h4>Amazing trend potential</h4>
                  <p>
                  Cybertruck token is poised to rack in numbers as the Cybertruck is one of the most renowned and controversial automobiles of recent times and Elon musk who has made a stamp in the crypto space TWEETS about the “CYBERTRUCK” very often which would bring more awareness and attention to the project 
                  </p>
                </div>
              </li>
             
            </ul>
          </div>
          <div className="maps_right">
            {/* <img src="assets/images/map/maps.png" alt="Maps" /> */}
            <div className="maps_video">
            <iframe src="assets/images/map/cybt.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Map;
