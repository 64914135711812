import React from "react";

function HowToBuy() {
  return (
    <section className="how_to_buy_area section_padding" id="how_to_buy">
      <div className="how_to_but_bg">
        <div className="container">
          <div className="how_to_main">
            <div className="section_title">
              <h2>HOW TO BUY $CYBT TOKEN</h2>
              <p>3 steps - Safe and Easy</p>
            </div>
            <div className="how_to_row">
              <div className="how_to_single">
                <span>1</span>
                <h4>Connect Wallet</h4>
                <p>
                Connect your wallet it to this panel ensure it is a decentralized wallet on the Ethereum Network, like MetaMask, Trust Wallet, and others. Make sure the wallet has some ETH or USDT for the purchase of CYBT and some ETH for the gas fees on it.
                </p>
              </div>
              <div className="how_to_single">
                <span>2</span>
                <h4>Choose Amount</h4>
                <p>
                Enter the amount of USDT or ETH you want to invest into $CYBT and let the website display how many tokens you’ll receive!
                </p>
              </div>
              <div className="how_to_single">
                <span>3</span>
                <h4>Here You Go!</h4>
                <p>
                Click on “Buy“ and approve in your wallet. If it does not work, try the in-app browser of your Wallet. You will be able to claim your token on this website when the presale is over!
                </p>
              </div>
              </div>
              
            {/* <div className="watch_tutorial"> 
            <div className="watch_tutorial1"><a href="https://www.youtube.com/watch?v=y9u3h2dDjlM" target="_blank"><p>Watch Tutorial</p>
             </a></div></div> */}


            <div className="how_to_pp">
              <p>
                Note: DO  NOT BUY $CYBT on any other websites as this is the only the official website to buy CYBERTRUCK TOKEN. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowToBuy;
