import React from "react";

function Faqs() {
  return (
    <section className="question_area section_padding" id="faq">
      <div className="container">
        <div className="section_title">
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
          <p>
          To help explain common understanding questions, we've compiled a list of the most often requested questions and responses to our team.
          </p>
        </div>
        <div className="question">
          <div className="demo faq_question_2">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                    >
                      <i className="fa fa-plus" /> What is the purpose of your crypto meme project?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne"
                  className="collapse "
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                    $CYBT project aims to democratize meme creation and ownership by leveraging blockchain technology. We're building a platform where users can create, share, and monetize memes using our native token. This empowers meme creators and fosters a vibrant meme economy.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link show_colups"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                    >
                      <i className="fa fa-plus" />
                      Where Can I buy CYBT??
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                    You can purchase CYBT by visiting our website www.cybertrucktoken.xyz and following the purchase instructions 
                  And after the conclusion of the presale you can purchase CYBT on UNISWAP.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                    >
                      <i className="fa fa-plus" />
                      How Many Stages will exist for the presale and days for each presale stages?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                    We have 3 stages for the presale, each stage lasts 20 days and during this period the Team  educates the community on all the available informations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                    >
                      <i className="fa fa-plus" />
                      How can I stay updated?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                    Stay connected by following our social media channels (Twitter, Telegram, Discord) and subscribing to our email newsletter. We regularly share updates, announcements, and community events to keep our supporters informed.

                    </p>
                  </div>
                </div>
              </div>
              {/* Faqs 5  */}
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                    >
                      <i className="fa fa-plus" />
                     I need help, How can I contact the support?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                    You can contact the support team by visiting our website or joining our telegram channel. Scroll Up or Down and click on the Telegram, Twitter Icon and it will take you directly to our community group.

                    </p>
                  </div>
                </div>
              </div>
              {/* Faqs 5 end here */}


              {/* Faqs 6 */}
              <div className="card">
                <div className="card-header" id="headingSix">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSix"
                    >
                      <i className="fa fa-plus" />
                      What happens if all the token are not sold out after the presale?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseSix"
                  className="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                    If peradventure the allocated tokens for the presale phases are not sold out at the conclusion of the said phases, the remaining unsold tokens will be burned. “Burning” tokens means permanently removing a number of tokens from circulation. Token burning is intended to have a deflationary impact on the value of an asset, boosting its value by reducing the supply available.
                    </p>
                  </div>
                </div>
              </div>
              {/* Faqs 6 ends here */}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
